import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import axios from 'axios'
import Vue from 'vue'
import router from '../../../router'

export default function useMaterialRequestsList() {
  const toast = useToast()
  const refAssetListTable = ref(null)
  const refAssetListTableNA = ref(null) // Kept
  const refAssetListTableRejected = ref(null) // Added
  const refAssetListTableFinished = ref(null)
  const tableColumns = [
    { key: 'purchase_order_number', sortable: false },
    { key: 'purchase_order_receive_number', sortable: false },
    { key: 'total_items', sortable: false },
    { key: 'Action', sortable: true },
  ]
  const perPage = ref(10)
  const perPageNA = ref(10) // Kept
  const perPageRejected = ref(10) // Added
  const perPageFinished = ref(10)
  const totalAsset = ref(0)
  const totalAssetNA = ref(0) // Kept
  const totalAssetRejected = ref(0) // Added
  const totalAssetFinished = ref(0)
  const currentPage = ref(1)
  const currentPageNA = ref(1) // Kept
  const currentPageRejected = ref(1) // Added
  const currentPageFinished = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const searchQueryNA = ref('') // Kept
  const searchQueryRejected = ref('') // Added
  const searchQueryFinished = ref('')
  const sortBy = ref('transfer_number')
  const sortByNA = ref('transfer_number') // Kept
  const sortByRejected = ref('transfer_number') // Added
  const sortByFinished = ref('transfer_number')
  const isSortDirDesc = ref(true)
  const isSortDirDescNA = ref(true) // Kept
  const isSortDirDescRejected = ref(true) // Added
  const isSortDirDescFinished = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refAssetListTable.value ? refAssetListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAsset.value,
    }
  })
  const dataMetaNA = computed(() => { // Kept
    const localItemsCount = refAssetListTableNA.value ? refAssetListTableNA.value.localItems.length : 0
    return {
      from: perPageNA.value * (currentPageNA.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageNA.value * (currentPageNA.value - 1) + localItemsCount,
      of: totalAssetNA.value,
    }
  })
  const dataMetaRejected = computed(() => { // Added
    const localItemsCount = refAssetListTableRejected.value ? refAssetListTableRejected.value.localItems.length : 0
    return {
      from: perPageRejected.value * (currentPageRejected.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageRejected.value * (currentPageRejected.value - 1) + localItemsCount,
      of: totalAssetRejected.value,
    }
  })
  const dataMetaFinished = computed(() => {
    const localItemsCount = refAssetListTableFinished.value ? refAssetListTableFinished.value.localItems.length : 0
    return {
      from: perPageFinished.value * (currentPageFinished.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageFinished.value * (currentPageFinished.value - 1) + localItemsCount,
      of: totalAssetFinished.value,
    }
  })

  const refetchData = () => {
    refAssetListTable.value.refresh()
    refAssetListTableNA.value.refresh()
    refAssetListTableFinished.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, sortBy, currentPageFinished, perPageFinished, searchQueryFinished, sortByFinished, currentPageNA, perPageNA, searchQueryNA, sortByNA, currentPageRejected, perPageRejected, searchQueryRejected, sortByRejected, roleFilter, statusFilter, isSortDirDesc, isSortDirDescFinished, isSortDirDescNA, isSortDirDescRejected], () => {
    refetchData()
  })

  const fetchAssets = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPage.value * (currentPage.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_RECEIVE_ASSET}`, {
        headers,
        params: {
          limit: perPage.value,
          offset: to,
          search: searchQueryFinished.value,
          order_by: sortByFinished.value,
          order_sort: isSortDirDescFinished.value ? 'DESC' : 'ASC',
          is_received: false,
        },
      })
      .then(response => {
        totalAsset.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchAssetsNA = (ctx, callback) => { // Kept
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageNA.value * (currentPageNA.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_RECEIVE_ASSET}`, {
        headers,
        params: {
          limit: perPageNA.value,
          offset: to,
          search: searchQueryNA.value,
          order_by: sortByNA.value,
          order_sort: isSortDirDescNA.value ? 'DESC' : 'ASC',
          needs_approval: true,
          is_received: false,
        },
      })
      .then(response => {
        totalAssetNA.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchAssetsFinished = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageFinished.value * (currentPageFinished.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_RECEIVE_ASSET}`, {
        headers,
        params: {
          limit: perPageFinished.value,
          offset: to,
          search: searchQuery.value,
          order_by: sortBy.value,
          order_sort: isSortDirDesc.value ? 'DESC' : 'ASC',
          is_received: true,
        },
      })
      .then(response => {
        totalAssetFinished.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchAssets,
    fetchAssetsNA, // Kept
    fetchAssetsFinished,
    tableColumns,
    perPage,
    currentPage,
    totalAsset,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAssetListTable,
    refAssetListTableNA,
    refAssetListTableFinished,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    sortByFinished,
    isSortDirDescFinished,
    dataMetaFinished,
    currentPageFinished,
    totalAssetFinished,
    perPageFinished,
    searchQueryFinished,
    sortByNA, // Kept
    isSortDirDescNA, // Kept
    totalAssetNA, // Kept
    dataMetaNA, // Kept
    currentPageNA, // Kept
    perPageNA, // Kept
    searchQueryNA, // Kept
    sortByRejected, // Added
    isSortDirDescRejected, // Added
    totalAssetRejected, // Added
    dataMetaRejected, // Added
    currentPageRejected, // Added
    perPageRejected, // Added
    searchQueryRejected, // Added
    // Extra Filters
    roleFilter,
    statusFilter,
  }
}
