<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-tabs>
        <b-tab title="On Going">
          <div class="m-2">

<!-- Table Top -->
<b-row>

  <!-- Per Page -->
  <b-col
    cols="12"
    md="6"
    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
  >
    <label>Show</label>
    <v-select
      v-model="perPage"
      :options="perPageOptions"
      :clearable="false"
      class="per-page-selector d-inline-block mx-50"
    />
    <label>entries</label>
  </b-col>
  <b-col
    cols="12"
    md="6"
  >
    <div class="d-flex align-items-center justify-content-end">
      <b-form-input
        v-model="searchQuery"
        class="d-inline-block mr-1"
        placeholder="Search..."
      />
      <b-button
        v-if="createShow"
        variant="primary"
        @click="addNew()"
      >
        <span class="text-nowrap">Receive Asset</span>
      </b-button>
    </div>
  </b-col>
</b-row>
</div>
      <b-table
        ref="refAssetListTable"
        class="position-relative"
        :items="fetchAssets"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Purchase Order Number -->
        <template #cell(purchase_order_number)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.purchase_order_purchasing_receive.purchase_order.purchase_order_number }}</span>
          </div>
        </template>
        <!-- Column: Receiving Number -->
        <template #cell(purchase_order_receive_number)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.purchase_order_purchasing_receive.purchase_order_purchasing_receive_receiving_number }}</span>
          </div>
        </template>
        <!-- Column: Total Items -->
        <template #cell(total_items)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.purchase_order_warehouse_receive_total_received }}</span>
          </div>
        </template>
        <template #cell(transfer_created_time)="data">
            {{ dateSimple(data.item.transfer_created_time, 'YYYY-MM-DD HH:mm:ss') }}
        </template>
        <!-- Column: Action -->
        <template #cell(Action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="showDetail( data.item )">
              <feather-icon icon="ZoomInIcon" />
              <span class="align-middle ml-50">Detail</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('assets_confirm_accounting')==true && userRole=='finance' && data.item.purchase_order_warehouse_receive_confirmed_by_accounting==null" @click="receiveItem( data.item )">
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Receive</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('assets_confirm_lab_manager')==true && userRole=='laboratory-manager' && data.item.purchase_order_warehouse_receive_confirmed_by_lab_manager==null && data.item.purchase_order_warehouse_receive_confirmed_by_accounting!=null" @click="confirmItem( data.item )">
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalAssetes"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      </b-tab>
      <b-tab title="Needs Approval">
        <div class="m-2">

<!-- Table Top -->
<b-row>

  <!-- Per Page -->
  <b-col
    cols="12"
    md="6"
    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
  >
    <label>Show</label>
    <v-select
      v-model="perPageNA"
      :options="perPageOptions"
      :clearable="false"
      class="per-page-selector d-inline-block mx-50"
    />
    <label>entries</label>
  </b-col>
  <b-col
    cols="12"
    md="6"
  >
    <div class="d-flex align-items-center justify-content-end">
      <b-form-input
        v-model="searchQueryNA"
        class="d-inline-block mr-1"
        placeholder="Search..."
      />
      <b-button
        v-if="createShow"
        variant="primary"
        @click="addNew()"
      >
        <span class="text-nowrap">Add Asset</span>
      </b-button>
    </div>
  </b-col>
</b-row>
</div>
      <b-table
        ref="refAssetListTableNA"
        class="position-relative"
        :items="fetchAssetsNA"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortByNA"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDescNA"
      >
        <!-- Column: Number -->
        <template #cell(purchase_order_number)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.purchase_order_purchasing_receive.purchase_order.purchase_order_number }}</span>
          </div>
        </template>
        <!-- Column: Receive Number -->
        <template #cell(purchase_order_receive_number)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.purchase_order_purchasing_receive.purchase_order_purchasing_receive_receiving_number }}</span>
          </div>
        </template>
        <!-- Column: Total Items -->
        <template #cell(total_items)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.purchase_order_warehouse_receive_total_received }}</span>
          </div>
        </template>
        <!-- Column: Action -->
        <template #cell(Action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="showDetail( data.item )">
              <feather-icon icon="ZoomInIcon" />
              <span class="align-middle ml-50">Detail</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('assets_confirm_accounting')==true && userRole=='finance' && data.item.purchase_order_warehouse_receive_confirmed_by_accounting==null" @click="receiveItem( data.item )">
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Receive</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="userRole=='laboratory-manager' && data.item.purchase_order_warehouse_receive_confirmed_by_lab_manager==null && data.item.purchase_order_warehouse_receive_confirmed_by_accounting!=null" @click="confirmItem( data.item )">
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMetaNA.from }} to {{ dataMetaNA.to }} of {{ dataMetaNA.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPageNA"
              :total-rows="totalAssetNA"
              :per-page="perPageNA"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      </b-tab>
      <b-tab title="Finished">
        <div class="m-2">

<!-- Table Top -->
<b-row>

  <!-- Per Page -->
  <b-col
    cols="12"
    md="6"
    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
  >
    <label>Show</label>
    <v-select
      v-model="perPageFinished"
      :options="perPageOptions"
      :clearable="false"
      class="per-page-selector d-inline-block mx-50"
    />
    <label>entries</label>
  </b-col>
  <b-col
    cols="12"
    md="6"
  >
    <div class="d-flex align-items-center justify-content-end">
      <b-form-input
        v-model="searchQueryFinished"
        class="d-inline-block mr-1"
        placeholder="Search..."
      />
      <b-button
        v-if="createShow"
        variant="primary"
        @click="addNew()"
      >
        <span class="text-nowrap">Add Asset</span>
      </b-button>
    </div>
  </b-col>
</b-row>
</div>
      <b-table
        ref="refAssetListTableFinished"
        class="position-relative"
        :items="fetchAssetsFinished"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortByFinished"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDescFinished"
      >
        <!-- Column: Number -->
        <template #cell(purchase_order_number)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.purchase_order_purchasing_receive.purchase_order.purchase_order_number }}</span>
          </div>
        </template>
        <!-- Column: Sender -->
        <template #cell(purchase_order_receive_number)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.purchase_order_purchasing_receive.purchase_order_purchasing_receive_receiving_number }}</span>
          </div>
        </template>
        <!-- Column: Total Items -->
        <template #cell(total_items)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.purchase_order_warehouse_receive_total_received }}</span>
          </div>
        </template>
        <!-- Column: Action -->
        <template #cell(Action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="showDetail( data.item )">
              <feather-icon icon="ZoomInIcon" />
              <span class="align-middle ml-50">Detail</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('assets_confirm_accounting')==true && userRole=='finance' && data.item.purchase_order_warehouse_receive_confirmed_by_accounting==null" @click="receiveItem( data.item )">
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Receive</span>
            </b-dropdown-item>
            <b-dropdown-item
v-if="hasPermission('assets_confirm_lab_manager')==true && userRole=='laboratory-manager' && data.item.purchase_order_warehouse_receive_confirmed_by_lab_manager==null && data.item.purchase_order_warehouse_receive_confirmed_by_accounting!=null" @click="confirmItem( data.item )">
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMetaFinished.from }} to {{ dataMetaFinished.to }} of {{ dataMetaFinished.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPageFinished"
              :total-rows="totalAssetsFinished"
              :per-page="perPageFinished"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, VBModal, BListGroup, BListGroupItem, BTabs, BTab,
} from 'bootstrap-vue'
import axios from 'axios'
import vSelect from 'vue-select'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import moment from 'moment'
import { VueHorizontalTimeline } from "vue-horizontal-timeline"
import AssetList from './ReceiveList'

const userRole = ''

const messageBox = ''
const to = ''
const cc = ''
const reason = ''
const AssetId = ''
const messagesList = []
const statusItems = []
const items = []
const userId = getUserData().user_id
const modalDetailShow = false
const modalMessage = false
const userBranchId = getUserData().user_branch_id
export default {
  components: {
    VueHorizontalTimeline,
    BListGroup,
    BListGroupItem,
    // AssetesListFilters,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BTab,
    vSelect,
  },
  data() {
    return {
      userBranchId,
      items,
      statusItems,
      AssetId,
      userId,
      messagesList,
      createShow: true,
      excludeRoleToCreate: ['laboratory-manager'],
      userRole,
  }
},
  mounted() {
    this.userRole = this.$cookies.get('UserRole')
    console.log("USER ROLE", this.userRole)
    if (this.excludeRoleToCreate.includes(this.userRole)) {
      this.createShow = false
    }
    this.$root.$on('refreshTable', text => {
    console.log(text) // here you need to use the arrow function
    this.$refs.refAssetListTable.refresh()
    this.$refs.refAssetListTableNA.refresh()
    this.$refs.refAssetListTableFinished.refresh()
  })
  },
  setup() {
    const BRANCH_APP_STORE_MODULE_NAME = 'app-transfers'
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
      })

    const isAddNewAssetSidebarActive = ref(false)

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      searchQueryNA,
      fetchAssetsFinished,
      sortByFinished,
      isSortDirDescFinished,
      dataMetaFinished,
      currentPageFinished,
      totalAssetsFinished,
      perPageFinished,
      fetchAssetsNA,
      sortByNA,
      isSortDirDescNA,
      dataMetaNA,
      currentPageNA,
      totalAssetNA,
      perPageNA,
      sortByRejected,
      isSortDirDescRejected,
      dataMetaRejected,
      currentPageRejected,
      totalAssetRejected,
      perPageRejected,
      fetchAssets,
      tableColumns,
      perPage,
      currentPage,
      totalAssetes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAssetListTable,
      refAssetListTableNA,
      refAssetListTableFinished,
      searchQueryFinished,
      refetchData,

      // UI
      resolveAssetRoleVariant,
      resolveAssetRoleIcon,
      resolveAssetStatusVariant,

      // Extra Filters
      roleFilter,
      statusFilter,
    } = AssetList()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    return {
      searchQueryFinished,
      searchQueryNA,
      to,
      cc,
      reason,
      messageBox,
      modalDetailShow,
      modalMessage,
      // Sidebar
      isAddNewAssetSidebarActive,
      userRole,
      fetchAssets,
      tableColumns,
      perPage,
      currentPage,
      totalAssetes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAssetListTable,
      refAssetListTableNA,
      refAssetListTableFinished,
      refetchData,
      fetchAssetsFinished,
      sortByFinished,
      isSortDirDescFinished,
      dataMetaFinished,
      currentPageFinished,
      totalAssetsFinished,
      perPageFinished,
      fetchAssetsNA,
      sortByNA,
      isSortDirDescNA,
      dataMetaNA,
      currentPageNA,
      totalAssetNA,
      perPageNA,
      sortByRejected,
      isSortDirDescRejected,
      dataMetaRejected,
      currentPageRejected,
      totalAssetRejected,
      perPageRejected,
      // Filter
      avatarText,

      // UI
      resolveAssetRoleVariant,
      resolveAssetRoleIcon,
      resolveAssetStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
    }
  },
  methods: {
      deleteAsset(id) {
        const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
          axios
              .get(`${process.env.VUE_APP_API_DELETE_TRANSFER}${id}`, { headers })
              .then(response => {
                console.log(response)
                if (response.data.success === true) {
                  this.$refs.refAssetListTable.refresh()
                  this.$refs.refAssetListTableNA.refresh()
                  this.$refs.refAssetListTableFinished.refresh()
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Congratulation',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Your data has been deleted!',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Notification',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: 'Something went wrong',
                    },
                  })
                }
              })
              .catch(err => {
                  console.log(err)
              })
      },
      editAsset(data) {
      localStorage.setItem('AssetEdit', JSON.stringify(data))
          this.$router.push({ name: 'apps-transfers-edit' })
      },
      confirmAssetByLabManager(item) {
      localStorage.setItem('AssetReceiveDetail', JSON.stringify(item))
      this.$router.push({ name: 'apps-transfers-confirm-lab-manager' })
      },
      confirmAssetByLabManagerSender(item) {
      localStorage.setItem('AssetReceiveDetail', JSON.stringify(item))
      this.$router.push({ name: 'apps-transfers-confirm-lab-manager-sender' })
      },
      confirmAssetByWarehouseSender(item) {
      localStorage.setItem('AssetReceiveDetail', JSON.stringify(item))
      this.$router.push({ name: 'apps-transfers-confirm-warehouse-sender' })
      },
      confirmAssetByWarehouseReceiver(item) {
      localStorage.setItem('AssetReceiveDetail', JSON.stringify(item))
      this.$router.push({ name: 'apps-transfers-confirm-warehouse-receiver' })
      },
      confirmAssetByRegionalManager(item) {
      localStorage.setItem('AssetReceiveDetail', JSON.stringify(item))
      this.$router.push({ name: 'apps-transfers-confirm-regional-manager' })
      },
      confirmAssetByDivisionHead(item) {
      localStorage.setItem('AssetReceiveDetail', JSON.stringify(item))
      this.$router.push({ name: 'apps-transfers-confirm-division-head' })
      },
      confirmAssetByFACoalManager(item) {
      localStorage.setItem('AssetReceiveDetail', JSON.stringify(item))
      this.$router.push({ name: 'apps-transfers-confirm-fa-coal-manager' })
      },
      declineAsset(id) {
        const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
        const body = {
        time: moment(),
        }
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DECLINE_TRANSFER}/${id}`
        axios
          .post(url, body, { headers })
      .then(async response => {
        if (response.data.success === true) {
          this.$refs.refAssetListTable.refresh()
          this.$refs.refAssetListTableNA.refresh()
          this.$refs.refAssetListTableFinished.refresh()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Confirm Asset Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      },
    addNew() {
          this.$router.push({ name: 'apps-assets-receive' })
    },
    dateSimple(value, format = 'YYYY-MM-DD') {
        let dateRet = ''
        if (value !== null) {
          dateRet = moment(String(value)).format(format)
        } else { dateRet = null }
        return dateRet
    },
    showDetail(item) {
      localStorage.setItem('AssetReceiveDetail', JSON.stringify(item))
      this.$router.push({ name: 'apps-assets-receive-details' })
    },
    receiveItem(item) {
      localStorage.setItem('AssetReceiveDetail', JSON.stringify(item))
      this.$router.push({ name: 'apps-assets-receive-forms' })
    },
    confirmItem(item) {
      localStorage.setItem('AssetReceiveDetail', JSON.stringify(item))
      this.$router.push({ name: 'apps-assets-confirm-forms' })
    },
    hasPermission(permission) {
      const permissions = this.$cookies.get('userPermissions').split(',')
      return permissions.includes(permission) ? true : false
    },
    async sendMessage() {
      const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
      const id = this.AssetId
      const Message = this.messageBox
      const body = {
        transfer_chat_transfer_id: id,
        transfer_chat_text: Message,
        time: moment(),
      }
    axios
      .post(`${process.env.VUE_APP_API_CREATE_MATERIAL_REQUEST_CHATS}`, body, { headers })
      .then(async response => {
        if (response.data.success === true) {
          this.messageBox = ''
    axios
      .get(`${process.env.VUE_APP_API_GET_MATERIAL_REQUEST_CHATS}${id}`, { headers })
      .then(response2 => {
        if (response2.data.success === true) {
          console.log('Fetching Data')
      if (response2.data.data) {
      messagesList.splice(0, messagesList.length)
        response2.data.data.map(value => {
          messagesList.push(value)
          return value.invoiceMessage
        })
      }
        }
        })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
